import { UserAuth } from "../context/AuthContext";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  onSnapshot,
  collection,
  query,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import CsvDownload from "react-json-to-csv";

function Admin() {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const day = new Date().getDate();

  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const [list, setList] = useState();

  const handleLogout = async () => {
    try {
      let msg = user.email;
      await logout();
      navigate("/");
      console.log(msg + " has logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    const q = query(collection(db, "waitlist"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let listArr = [];
      querySnapshot.forEach((doc) => {
        // listArr.push({ ...doc.data() });
        listArr.push({
          name: doc.data().name,
          email: doc.data().email,
          tel: doc.data().tel,
          accounts: doc.data().accounts,
          date: doc.data().date
        });
      });
      setList(listArr);
    });
    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form">
      <div className="login">admin page</div>

      <CsvDownload data={list} filename={`${year}-${month}-${day}.csv`} />

      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default Admin;
