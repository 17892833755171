import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { UserAuth } from "../context/AuthContext";

const Footer = ({ vals }) => {
  const { user } = UserAuth();
  return (
    <>
      {!vals && !user ? (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, x: "100vw" }}
            transition={{
              type: "spring",
              duration: 1.5,
              delay: 1,
              bounce: 0,
            }}
            className="footer-container"
          >
            <img src="Logo.svg" alt="woldo-logo" className="logo-small" />
          </motion.div>
        </AnimatePresence>
      ) : null}
    </>
  );
};

export default Footer;
