import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";

const Login = () => {
  const { signIn, user } = UserAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

//   useEffect(() => {
//     if (user) {
//       navigate("/admin");
//     }
//   }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signIn(email, password);
      navigate("/admin");
      
    } catch (e) {
      setError(e.message);
      
      alert("Invalid email or password. Please try again.");
    }
  };

  return (
    <div
      className=""
    >
      <h1 className="login">Woldo</h1>
      <br />
      <h1 className="login">Sign in to your account</h1>
      <br />

      <form
        onSubmit={handleSubmit}
        className="login"
      >
        <div className="login">
          <label className="login" htmlFor="email">
            Email
          </label>
          <input
            
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            name="email"
            type="text"
          />
        </div>

        <div className="login">
          <label className="login" htmlFor="password">
            Password
          </label>
          <input
            
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            name="password"
            type="password"
          />
        </div>

        <button>Sign in</button>
      </form>
    </div>
  );
};

export default Login;