import { Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Login from "./components/Login";
import { AuthContextProvider } from "./context/AuthContext";
import Admin from "./components/Admin";
import ProtectedRoute from "./components/ProtectedRoute";
import { useState } from "react";
import Footer from "./components/Footer";

const App = () => {
  const [vals, setVals] = useState();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <AuthContextProvider>
      <div className="main-container">
        <Routes>
          <Route path="/" element={<Main vals={vals} setVals={setVals} />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Footer vals={vals} />
      </div>
    </AuthContextProvider>
  );
};

export default App;
