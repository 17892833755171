import React from "react";
import WaitlistForm from "./WaitlistForm";

function Main({ vals, setVals }) {
  return (
    <>
      <WaitlistForm vals={vals} setVals={setVals} />
    </>
  );
}

export default Main;
