import { useFormik } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

const WaitlistForm = ({ setVals, vals }) => {
  const myDate = new Date();

  const addToList = async (name, email, accounts) => {
    await addDoc(collection(db, "waitlist"), {
      name: name,
      email: email,
      // tel: tel,
      accounts: accounts,
      date: myDate.toISOString().split("T")[0],
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      accounts: "",
    },
    onSubmit: (values) => {
      addToList(values.name, values.email, values.accounts);
      setVals(values);
      logEvent(analytics, "sign_up", {
        content_type: 'button',
        content_id: values.name
      });
      formik.resetForm();
    },
    validate: (values) => {
      let errors = {};

      if (!values.name) {
        errors.name = "Campo obbligatorio";
      }

      if (!values.email) {
        errors.email = "Campo obbligatorio";
      } else if (
        /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\. [a-zA-Z0-9-]+)*$/.test(
          values.email
        )
      ) {
        errors.email = "Formato email non valido";
      }

      // if (!values.tel) {
      //   errors.tel = "Campo obbligatorio";
      // }

      if (!values.accounts) {
        errors.accounts = "Campo obbligatorio";
      }

      return errors;
    },
  });

  return (
    <AnimatePresence>
      {!vals ? (
        <motion.div
          initial={{ x: "-100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0, x: "100vw" }}
          transition={{ type: "spring", duration: 1, bounce: 0 }}
          key="box"
          className="landing-container"
        >
          <form className="first-part" onSubmit={formik.handleSubmit}>
            <div className="hero">
              <div className="logo-container">
                <img src="Logo.svg" alt="woldo-logo" className="logo" />
              </div>
              <div className="description-container">
                <h1 className="woldo-icon">Benvenuto su Woldo</h1>
                <span className="description">
                  <b>Woldo</b> è la prima applicazione gratuita che ti permette
                  di tenere sott{"’"}occhio tutti i tuoi conti correnti.{" "}
                  <br></br>
                </span>
                <p className="description">
                  Iscriviti alla nostra <b>waitlist</b> e diventa uno dei primi
                  beta tester di Woldo.
                </p>
                <div className="available-wrapper">
                  <p className="available">
                    Accesso alla beta disponibile solo per i primi <b>100</b>{" "}
                    iscritti
                  </p>
                </div>
              </div>

              <h2 className="cta">Iscriviti ora per accedere alla beta</h2>
            </div>

            <div className="input-wrapper">
              <motion.div
                initial={{ x: "-5vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0, x: "100vw" }}
                transition={{
                  type: "spring",
                  duration: 0.5,
                  delay: 0.5,
                  bounce: 0,
                }}
                className="input-box"
              >
                <label htmlFor="name">Nome e Cognome</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Marco Rossi"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div
                    className="error"
                    style={{ color: "rgb(214, 125, 125)", opacity: 0.5 }}
                  >
                    {formik.errors.name}
                  </div>
                ) : (
                  <div style={{ opacity: 0, cursor: "default" }}>
                    Placeholder
                  </div>
                )}
              </motion.div>

              <motion.div
                className="input-box"
                initial={{ x: "-5vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0, x: "100vw" }}
                transition={{
                  type: "spring",
                  duration: 0.5,
                  delay: 0.6,
                  bounce: 0,
                }}
              >
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="marcorossi@example.com"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div
                    className="error"
                    style={{ color: "rgb(214, 125, 125)", opacity: 0.5 }}
                  >
                    {formik.errors.email}
                  </div>
                ) : (
                  <div style={{ opacity: 0, cursor: "default" }}>
                    Placeholder
                  </div>
                )}
              </motion.div>

              {/* <motion.div
                className="input-box"
                initial={{ x: "-5vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0, x: "100vw" }}
                transition={{
                  type: "spring",
                  duration: 0.5,
                  delay: 0.7,
                  bounce: 0,
                }}
              >
                <label htmlFor="tel">Telefono</label>
                <input
                  type="tel"
                  name="tel"
                  placeholder="+39 1234567890"
                  value={formik.values.tel}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.tel && formik.errors.tel ? (
                  <div
                    className="error"
                    style={{ color: "rgb(214, 125, 125)", opacity: 0.5 }}
                  >
                    {formik.errors.tel}
                  </div>
                ) : (
                  <div style={{ opacity: 0, cursor: "default" }}>
                    Placeholder
                  </div>
                )}
              </motion.div> */}

              <motion.div
                className="input-box"
                initial={{ x: "-5vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0, x: "100vw" }}
                transition={{
                  type: "spring",
                  duration: 0.5,
                  delay: 0.8,
                  bounce: 0,
                }}
              >
                <label htmlFor="tel">
                  Conti correnti, wallet crypto, exchange che utilizzi di solito
                </label>
                <input
                  type="accounts"
                  name="accounts"
                  placeholder="Unicredit, Coinbase, eToro ..."
                  value={formik.values.accounts}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.accounts && formik.errors.accounts ? (
                  <div
                    className="error"
                    style={{ color: "rgb(214, 125, 125)", opacity: 0.5 }}
                  >
                    {formik.errors.accounts}
                  </div>
                ) : (
                  <div style={{ opacity: 0, cursor: "default" }}>
                    Placeholder
                  </div>
                )}
              </motion.div>
            </div>

            <motion.button
              initial={{ x: "-10vw", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ opacity: 0, x: "100vw" }}
              transition={{
                type: "spring",
                duration: 0.5,
                delay: 0.9,
                bounce: 0,
              }}
              type="submit"
            >
              <p className="sign-up">Iscriviti ora</p>
              <img src="Avanti.svg" alt="forward" />
            </motion.button>
          </form>

          <motion.div
            initial={{ scale: 1.2 }}
            animate={{ scale: 1 }}
            exit={{ opacity: 0, x: "100vw" }}
            transition={{
              type: "spring",
              duration: 2,
              delay: 0.4,
              bounce: 0,
            }}
            className="image-container"
          />
        </motion.div>
      ) : (
        <motion.div
          initial={{ x: "100vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "100vw", opacity: 0 }}
          transition={{ type: "spring", duration: 1, bounce: 0 }}
          key="box2"
          className="goodbye"
        >
          <h3 className="footer-text">
            Non vediamo l'ora che tu scopra <b>Woldo</b>. <br></br>Invieremo gli
            inviti alla beta gradualmente.
          </h3>

          {/* <button onClick={() => setVals(undefined)}>Go back</button> */}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WaitlistForm;
